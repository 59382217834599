var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-warper", attrs: { id: "app-warper" } },
    [
      _c(
        "el-container",
        { attrs: { id: "app-container" } },
        [
          _c("el-main", [_c("router-view")], 1),
          _c("el-footer", [
            _c(
              "div",
              { staticClass: "footer-container" },
              [
                _c("div", { staticClass: "link-background" }, [
                  _c("div", { staticClass: "link-container" }, [
                    _c("div", { staticClass: "link-item left-link-item" }, [
                      _c("div", { staticClass: "link-title" }, [
                        _vm._v("友情链接")
                      ]),
                      _c(
                        "div",
                        { staticClass: "top-link-flex" },
                        _vm._l(_vm.powerWebs, function(item) {
                          return _c(
                            "a",
                            {
                              key: item.link,
                              staticClass: "footer-link",
                              attrs: { href: item.link, target: "_blank" }
                            },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "mobile-link-flex" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "left-select",
                              attrs: {
                                "value-key": "link",
                                placeholder: "-----省市知识产权局-----"
                              },
                              on: { change: _vm.powerWebChane },
                              model: {
                                value: _vm.mPowerWeb,
                                callback: function($$v) {
                                  _vm.mPowerWeb = $$v
                                },
                                expression: "mPowerWeb"
                              }
                            },
                            _vm._l(_vm.mPowerWebs, function(item) {
                              return _c("el-option", {
                                key: item.link,
                                attrs: { label: item.label, value: item.link }
                              })
                            }),
                            1
                          ),
                          _c("br"),
                          _c(
                            "el-select",
                            {
                              staticClass: "right-select",
                              attrs: {
                                "value-key": "link",
                                placeholder: "-----本市政务网-----"
                              },
                              on: { change: _vm.goverWebChane },
                              model: {
                                value: _vm.goverWeb,
                                callback: function($$v) {
                                  _vm.goverWeb = $$v
                                },
                                expression: "goverWeb"
                              }
                            },
                            _vm._l(_vm.goverWebs, function(item) {
                              return _c("el-option", {
                                key: item.link,
                                attrs: { label: item.label, value: item.link }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "link-item right-link-item" }, [
                      _c(
                        "div",
                        { staticClass: "link-flex" },
                        [
                          _c("div", { staticClass: "footer-link link-title" }, [
                            _vm._v("关于我们")
                          ]),
                          _c(
                            "router-link",
                            {
                              staticClass: "footer-link left-about",
                              attrs: { to: _vm.districtIntro, target: "_blank" }
                            },
                            [_vm._v("中心简介")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "footer-link right-about",
                              attrs: { to: _vm.contactUsLink, target: "_blank" }
                            },
                            [
                              _vm._v(" 联系我们 "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "96px",
                                    height: "96px",
                                    "margin-top": "17px"
                                  }
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%"
                                    },
                                    attrs: {
                                      src: require("@/assets/contact_mask.jpg")
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _c("el-divider"),
                _c("div", { staticClass: "power" }, [
                  _c("div", { staticClass: "power-content" }, [
                    _c("div", { staticClass: "power-text" }, [
                      _vm._v("中国（湖南）自由贸易试验区知识产权服务中心")
                    ]),
                    _c("div", { staticClass: "power-text web-power-text" }, [
                      _vm._v(
                        "长沙市长沙县东六路有色大厦公交站东100米科技新城C7栋4楼"
                      )
                    ]),
                    _c("div", { staticClass: "power-text mobile-power-text" }, [
                      _vm._v("长沙市长沙县东六路有色大厦公交站东100米"),
                      _c("br"),
                      _vm._v("科技新城C7栋4楼")
                    ]),
                    _c(
                      "a",
                      {
                        staticClass: "power-text beian-text",
                        attrs: {
                          href: "https://beian.miit.gov.cn",
                          target: "_blank"
                        }
                      },
                      [_vm._v("沪ICP备16040562号-8")]
                    ),
                    _c("div", { staticClass: "power-text" }, [
                      _vm._v("(网站维护由 湖南因问科技有限公司 提供）")
                    ])
                  ])
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }