<template>
  <div class="app-warper" id="app-warper">
    <el-container id="app-container">
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer>
        <div class="footer-container">
          <div class="link-background">
            <div class="link-container">
              <div class="link-item left-link-item">
                <div class="link-title">友情链接</div>
                <div class="top-link-flex">
                  <a class="footer-link" v-for="item in powerWebs" :key="item
                  .link" :href="item.link" target="_blank">{{ item.label }}</a>
                </div>
                <div class="mobile-link-flex">
                  <el-select v-model="mPowerWeb" class="left-select" value-key="link" placeholder="-----省市知识产权局-----" @change="powerWebChane">
                    <el-option v-for="item in mPowerWebs" :key="item.link" :label="item.label" :value="item.link"></el-option>
                  </el-select>
                  <br>
                  <el-select v-model="goverWeb" class="right-select" value-key="link" placeholder="-----本市政务网-----" @change="goverWebChane">
                    <el-option v-for="item in goverWebs" :key="item.link" :label="item.label" :value="item.link"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="link-item right-link-item">
                <div class="link-flex">
                  <div class="footer-link link-title">关于我们</div>
                  <router-link class="footer-link left-about" :to="districtIntro" target="_blank">中心简介</router-link>
                  <router-link class="footer-link right-about" :to="contactUsLink" target="_blank">
                     联系我们
                     <div style="width:96px;height:96px;margin-top:17px">
                        <img style="width:100%;height:100%" :src="require('@/assets/contact_mask.jpg')"/>
                     </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="power">
              <div class="power-content">
                <div class="power-text">中国（湖南）自由贸易试验区知识产权服务中心</div>
                <div class="power-text web-power-text">长沙市长沙县东六路有色大厦公交站东100米科技新城C7栋4楼</div>
                <div class="power-text mobile-power-text">长沙市长沙县东六路有色大厦公交站东100米<br/>科技新城C7栋4楼</div>
                <a class="power-text beian-text" href="https://beian.miit.gov.cn" target="_blank">沪ICP备16040562号-8</a>
                <div class="power-text">(网站维护由 湖南因问科技有限公司 提供）</div>
              </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>

import { mapState } from 'vuex'
export default {
  name: "",
  data() {
    return {
      powerWebs: [
        {label: '国家知识产权局', link: 'https://www.cnipa.gov.cn'},
        {label: '湖南省知识产权局', link: 'http://amr.hunan.gov.cn/amr/ztx/zscqqsx/index.html'},
        {label: '长沙市知识产权局', link: 'http://csipo.changsha.gov.cn'},
        {label: '长沙经济开发区', link: 'http://www.cetz.gov.cn'},
        {label: '湖南省知识产权综合服务信息平台', link: 'http://tpr.sureserve.cn/zscqzh/ '},
       ],

       mPowerWeb:{},
       mPowerWebs: [
        {label: '国家知识产权局', link: 'https://www.cnipa.gov.cn'},
        {label: '湖南省知识产权局', link: 'http://amr.hunan.gov.cn/amr/ztx/zscqqsx/index.html'},
        {label: '长沙市知识产权局', link: 'http://csipo.changsha.gov.cn'},
       ],
       goverWeb:{},
       goverWebs:[
        {label: '长沙经济开发区', link: 'http://www.cetz.gov.cn'},
       ],
    };
  },
  computed: {
    ...mapState(['navbar']),
    headerHeight() {
      let width = window.screen.width
      if (width >= 600) {
        return this.navbar.menuTop ? '552px' : '292px'
      }
      else {
        return this.navbar.menuTop ? '325px' : '310px'
      }
    },
    districtIntro() {
      return this.$router.resolve({ name: 'districtIntro' }).href
    },
    contactUsLink() {
      return this.$router.resolve({ name: 'contactUs' }).href
    }
  },
  methods: {
    powerWebChane(link) {
      this.powerWeb = {}
      window.open(link)
      this.mPowerWeb = null
    },
    goverWebChane(link) {
      this.goverWeb = {}
      window.open(link)
    },
  },
};
</script>

<style lang="stylus" scoped>

.app-warper {
  height 100%
  overflow-y scroll
}

.el-container {
  min-height 100%
}

.el-header {
  padding 0px
  flex-flow 0
}

.el-main {
  padding 0px
  background #fff
}

.link-title {
  font-size 20px
  color #eaeaea
  font-weight bold
  flex-shrink 0
}

.power {
  display flex
  align-items center
  justify-content center

  .power-text {
    font-size 14px
    color rgba(255, 255, 255, 0.8);
    line-height 20px
    font-weight 300;
    text-align center
  }
}

 .power-content {
    display flex
    flex-direction column
    align-items center
  }

  .el-divider--horizontal {
  margin auto
  background #bbcbd8
}

.link-flex {
  display flex
}

.footer-link {
  font-size 14px
  color: rgba(255, 255, 255, 0.8);
  text-decoration none
  margin-right 32px
  &:hover {
    color white
  }
   font-family: PingFangSC-Light, PingFang SC;
   font-weight: 300;
   line-height: 20px;
}

.right-link-item {
  .footer-link {
      font-size: 18px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: rgba(255, 255, 255, 0.8);
      line-height: 25px;
      .link-title {
         color white
      }
      &:not(.link-title):hover {
         color white
      }
   }
}

@media only screen and (min-width: 600px) {

.el-header {
  flex-flow 0
}

.el-main {
  display flex
  flex-grow 1
  overflow hidden
}

.el-footer {
  height 359px !important
  padding 0
  background #323B4A;
}

.footer-container {
  margin-top 10px
}

.link-background {
  display flex
  .link-container {
    width 1004px
    margin 69px auto 34px auto
    display flex
    justify-content space-between
    align-items flex-start
  }
}

.link-item {
    align-items flex-start
}

.left-link-item {
  margin-right 60px
  flex 1
}

.link-title {
  font-weight: 800;
  color: #FFFFFF;
  line-height: 25px;
  font-size: 18px;
}

.link-flex {
  justify-content center
  align-items flex-start
}

.top-link-flex {
  line-height 24px
  display flex
  flex-wrap wrap
  margin-top 8px
}

.mobile-link-flex {
  display none
}

.right-link-item {
   margin-left 30px
   flex-shrink 0
   flex 1
  .left-about {
    margin-right 10px
  }

  .right-about {
    margin-left 10px
    text-align: center
  }
}

.el-select {
  width 360px
}

.el-divider--horizontal {
  width 1004px
  opacity: 0.5
}

.power {

  .power-left {
    margin-right 40px
  }

  .power-right {
    margin-left 40px
  }

  .power-text {
    margin 2px 0px
    text-decoration none
  }

  .beian-text {
    &:hover {
      color white
    }
  }
}

.mobile-power-text {
  display none
}

  .link-flex {
    display flex
    align-items flex-start
  }


}



// mobile


@media only screen and (max-width: 600px) {

.el-footer {
  height 437px !important
  padding 0
  background #6c6c6c
}

.link-background {
  .link-container {
    margin-left 20px
    padding-bottom 20px
    display flex
    flex-direction column
    justify-content space-between
  }
}

.link-item {
  margin-top 20px
  display flex
  flex-direction column
}

.link-title {
  margin-bottom 12px
}

.top-link-flex {
  display none
}

.el-select {
  width 240px
  flex-shrink 0
  flex-grow 1
  // height 20px
}

.mobile-link-flex {
  height 100px
  display flex
  justify-content space-between
  align-items center
  flex-wrap wrap
  padding-right 20px

  /deep/.el-input__inner {
    font-size 14px
    height 30px
    text-align center
  }

  /deep/.el-input__icon {
    height 30px
    line-height 30px
  }
}

  .footer-link {
    margin-right 20px
  }

.power {
  height 168px
  margin 0px 20px
}

.web-power-text {
  display none
}

  .power-text {
    margin 3px 0px
  }

  .el-divider--horizontal {
    width 360px
  }

  .beian-text {
    text-decoration none
    &:hover {
      color white
    }
  }
}


</style>